import React from 'react';
import ConfigHelper from 'configHelper';
import { DidomiSDK } from '@didomi/react';

type CookiesConfig = {
  apiKey: string;
  noticeId: string;
};

export default function CookieConsentDidomi() {
  const { apiKey, noticeId }: CookiesConfig = ConfigHelper.getClientConfig('api.cookies');
  return <DidomiSDK apiKey={apiKey} noticeId={noticeId} />;
}
